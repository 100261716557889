<!--
 * @Description:底部高德  反向寻车 reverseSearch
 * @Author: zhoucheng
 * @Github:
 * @Date: 2021-04-06 17:33:05
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <van-nav-bar title="反向寻车"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <div class="map"
         id="map"></div>
    <div id="panel"></div>

  </div>

</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import currentPosition from '@/utils/currentPosition'
export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      parkId: '', // 停车场id
      parkLng: 106, // 停车场经度
      parkLat: 29, // 停车场纬度
      mylatitude: 29.1, // 当前位置经度
      mylongitude: 106.1, // 当前位置纬度
      myaddress: '', // 当前位置名称
      map: '', // 地图对象
      panel: ''// 车辆形式路线绘制
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryCurrentPlace()
    this.queryParkLoc()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取当前位置经纬度
    queryCurrentPlace () {
      currentPosition(complete => {
        this.mylatitude = complete.position.lat
        this.mylongitude = complete.position.lng
        this.myaddress = complete.formattedAddress
        // 渲染地图
        this.initMap()
      }, error => {
        console.log(error, ' error')
      })
    },
    // 获取停车场经纬度
    queryParkLoc () {
      const info = {
        parkId: this.$route.query.parkId
      }
      this.$reverseSearch.queryParkAll(info).then(res => {
        this.parkLng = Number(res.resultEntity[0].longitude)
        this.parkLat = Number(res.resultEntity[0].latitude)
      })
    },
    // 初始化地图
    initMap () {
      var map = new window.AMap.Map('map', {
        mapStyle: 'amap://styles/这里放入样式ID', // 设置地图的显示样式
        resizeEnable: true,
        zoom: 11, // 缩放级别
        center: [Number(this.mylongitude), Number(this.mylatitude)] // 中心点坐标
      })
      this.map = map
      this.getLine()
      // console.log(map)
    },
    // 构造路线导航类
    getLine () {
      var that = this
      window.AMap.plugin('AMap.Driving', function () {
        var driving = new window.AMap.Driving({
          map: that.map,
          panel: 'panel'
        })
        driving.search(new window.AMap.LngLat(Number(that.mylongitude), Number(that.mylatitude)), new window.AMap.LngLat(Number(that.parkLng), Number(that.parkLat)), function (status, result) {
          // result 即是对应的驾车导航信息
          if (status === 'complete') {
            console.log('绘制驾车路线完成')
          } else {
            console.log('获取驾车数据失败：' + result)
          }
        })
      })
    },
    // 顶部返回事件
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .map {
    width: 100%;
    height: 60%;
  }
}
</style>
